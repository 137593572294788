<template>
  <div class="equipmentCom6" style="height: 100%;background: white;">
    <div id="maintablepage">
      <div class="maintablepage-content">
        <div class="maintablepage-header">
          <div class="maintablepage-header-name">统计报告</div>
        </div>
        <div id="maintablepage-buttons-box" class="maintablepage-buttons">
          <div id="1590936261223284737" class="maintablepage-btn" @click="open2">
            <i class="lowcode icon-plus"></i><span>新建</span>
          </div>
          <div id="1590936261223284738" class="maintablepage-btn" @click="reomveID">
            <i class="lowcode icon-shanchu-1"></i><span>删除</span>
          </div>
          <div id="1590936261223284739" class="maintablepage-btn" @click="open">
            <i style="width: 14px;height: 14px;">
              <img src="../../assets/icon/export.svg" alt="" srcset="">
            </i><span>数据导出</span>
          </div>
        </div>
        <el-table class="app-table" :data="tableData" height='400px' @selection-change="selectionChange"
          style="width: 100%;margin-top: 16px;">
          <el-table-column type="selection" width="55">
          </el-table-column>
          <el-table-column label="文件名称" prop="FILENAME" width="300">
          </el-table-column>
          <el-table-column label="所属机构" prop="REPORTORG">
          </el-table-column>
          <el-table-column label="时间类型" prop="TIMEAROUND">
          </el-table-column>
          <el-table-column label="操作" width="100">
            <template slot-scope="scope">
              <div class="downStyle">
                <i class="el-icon-document-add" @click="download(scope.row.FILEID, scope.row.FILENAME)"
                  style="cursor: pointer;"></i>
                <span @click="download(scope.row.FILEID, scope.row.FILENAME)">下载</span>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination style="display: flex;justify-content: flex-end;margin: 10px;" @size-change="handleSizeChange"
          @current-change="handleCurrentChange" :current-page="current"
          :page-sizes="[10, 20, 30, 40, 50, 60, 80, 100, 150, 200]" :page-size="10"
          layout="total, sizes, prev, pager, next, jumper" :total="count">
        </el-pagination>
      </div>
    </div>
    <el-dialog class="custom-dialog" v-if="dialogVisible1" title="数据导出" :visible.sync="dialogVisible1" width="40%">
      <OperationCom7></OperationCom7>
    </el-dialog>
    <el-dialog class="custom-dialog" v-if="dialogVisible2" title="生成报告" :visible.sync="dialogVisible2" width="40%">
      <OperationCom9></OperationCom9>
    </el-dialog>
  </div>
</template>
<script>
import { mapState } from "vuex";
import OperationCom7 from "@/component/OperationCom/OperationCom7";
import OperationCom9 from "@/component/OperationCom/OperationCom9";
export default {
  components: { OperationCom7, OperationCom9 },
  data() {
    return {
      dialogVisible2: false,
      dialogVisible1: false,
      tableData: [],
      count: 0,
      current: 1,
      currentPage: 1,
      pageSize: 10,
      reomveid: [],
    }
  },
  computed: {
    ...mapState(["currentuserinfo"]),
  },
  mounted() {
    this.getshijianList()
  },
  methods: {
    selectionChange(val) {
      this.reomveid = val
    },
    reomveID() {
      if (this.reomveid.length == 0) {
        this.$message.warning("请选择需要删除的数据！");
        return
      }
      let id = []
      let ids = this.reomveid;
      ids.forEach((item)=>{
        id.push(item.ID)
      })
      this.reomveData(id.join(','))
    },
    reomveData(id) {
      var url = Config.dev_url + `/api-apps/app/STATISTICALREPORTFILEINFO/reomveByIds?ids=${id}`
      this.$axios.delete(url).then((res) => {
        if (res.data.code == 0) {
          this.$message.success("删除成功");
          this.getshijianList()
        } else {
          this.$message.error('删除失败');
        }
      });
    },
    handleSizeChange(value) {
      this.pageSize = value;
      this.getshijianList()
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getshijianList()
    },
    open() {

      this.dialogVisible1 = true
    },
    open2() {

      this.dialogVisible2 = true
    },
    download(id, nameTitle) {
      this.$axios({
        url: Config.dev_url + "/api-file/file/download?fid=" + id,
        method: "get",
        responseType: "blob",
      }).then(res => {
        let blob = new Blob([res.data], {
          type: 'application/pdf'
        })
        let fileName = nameTitle + '.pdf'
        if (window.navigator.msSaveOrOpenBlob) {
          navigator.msSaveBlob(blob, fileName)
        } else {
          var link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = fileName
          link.click()
          window.URL.revokeObjectURL(link.href)
        }
      })
    },
    getshijianList() {
      var url =
        Config.dev_url +
        "/api-apps/app/STATISTICALREPORTFILEINFO/list"
      let data = {
        page: this.currentPage,
        limit: this.pageSize,
        p_query: `{\"links\":[{\"key\":\"CREATEBY\",\"links\":[],\"type\":\"Like\",\"value\":\"${this.currentuserinfo.USERNAME}\"}],\"type\":\"LinkAnd\"}`
      }
      this.$axios.post(url, data).then((res) => {
        if (res.data.code == 0) {

          this.tableData = res.data.data;
          this.count = Number(res.data.count);
        }
      });
    },
  }
}
</script>
<style lang="less">
#maintablepage {

  background: #ffffff;
  border-radius: 2px;
  display: flex;
  justify-content: space-between;

     width: calc(100% - 40px);
}

#maintablepage .maintablepage-content .maintablepage-header {
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#maintablepage .maintablepage-content {
  flex: auto;
  overflow-x: auto;
  overflow-y: hidden;
}

#maintablepage .maintablepage-content .maintablepage-buttons {
  max-height: 32px;
  display: flex;
  align-items: center;
}

.equipmentCom6 {
  height: calc(100vh - 142px);
  background: #fff;
  border-radius: 2px;
  display: flex;
  justify-content: space-between;
  margin: 20px;

  .el-form--inline .el-form-item__content {
    display: block;
  }

  .demo-table-expand {
    display: flex;
    justify-content: space-around;
  }
}

.app-table .el-table__header thead tr th {
  padding: 0;
  height: 40px;
  background: #f2f3f5;
}
</style>
<style lang="less" scoped>
.custom-dialog {
  /deep/.el-dialog__body {
    padding: 0 !important;
  }
}

.downStyle {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: rgb(28, 116, 241);

  &>span {
    margin: 0 3px;
    cursor: pointer;
    font-size: 13px;
  }
}
</style>