<template>
  <div class="OperationCom7">
    <div class="contant_t">
      <el-form label-position="left" :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px"
        class="demo-ruleForm">
        <el-form-item :label="LangHandler('reporttype','报告类型')" prop="region1">
          <el-select v-model="ruleForm.region1" :placeholder="LangHandler('sereporttype','请选择报告类型')" @change="onChange1">
            <el-option v-for="item in newarr3" :key="item.id" :label="item.name" :value="item.name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="LangHandler('grouptype','组织类型')" prop="region2">
          <el-select v-model="ruleForm.region2" :placeholder="LangHandler('segrouptype','请选择组织类型')" @change="onChange2">
            <el-option v-for="item in newarr1" :key="item.id" :label="item.name" :value="item.name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="LangHandler('group','组织')" prop="regionName">
          <el-input :placeholder="LangHandler('segroup','请选择组织')" v-model="ruleForm.regionName" :readonly="true" @click.native="onclick('站点')">
          </el-input>
          <i class="el-icon-search icon_style" @click="onclick('站点')"></i>
        </el-form-item>
        <el-form-item :label="LangHandler('timetype','时间类型')" prop="region3">
          <el-select v-model="ruleForm.region3" :placeholder="LangHandler('setimetype','请选择时间类型')" @change="onChange3">
            <el-option v-for="item in newarr2" :key="item.id" :label="item.name" :value="item.name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="LangHandler('time','时间')" prop="region3">
          <a-date-picker v-if="ruleForm.region3 == '天'" :placeholder="commonLangHandler('chuneng7_day','选天', getZEdata)" v-model="ruleForm.date1" />
          <a-month-picker v-if="ruleForm.region3 == '月'" :placeholder="commonLangHandler('chuneng7_month','选月', getZEdata)" v-model="ruleForm.date1" />
          <a-date-picker v-if="ruleForm.region3 == '年'" :placeholder="commonLangHandler('chuneng7_year','选年', getZEdata)" v-model="ruleForm.date1" :open='yearShowOne'
            mode="year" format='YYYY' @openChange="openChangeOne" @panelChange="panelChangeOne" />
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="footerBtn el-dialog__footer">
      <el-button size="mini" @click="xbtn">{{LangHandler('cancle','取 消')}}</el-button>
      <el-button size="mini" @click="preview" type="primary" style="background: #3366ff">{{LangHandler('preview','预览')}}</el-button>
    </span>
    <el-dialog :title="titleName" v-if="dialogVisible2" :visible.sync="dialogVisible2" :append-to-body="true"
      class="reseDialogt custom-dialog" width="50%">
      <div class="elDialog">
        <div class="elDialog_div">
          <span v-if="columnNum == '站点'">{{LangHandler('deptid','部门编号')}}</span><span v-if="columnNum == '模板' || columnNum == '设备'">类型</span><el-input
            v-model="input" :placeholder="LangHandler('inputdeptid','请输入部门编号')"></el-input>
        </div>
        <div class="elDialog_div">
          <span v-if="columnNum == '站点'">{{LangHandler('deptname','部门名称')}}</span>
          <span v-if="columnNum == '模板' || columnNum == '设备'">{{LangHandler('dname','名称')}}</span><el-input v-model="input1"
            :placeholder="LangHandler('inputdeptname','请输入部门名称')"></el-input>
        </div>
        <div class="elDialog_div">
          <el-button size="mini" type="primary" style="background: #1c74f1;" @click="onclick(columnNum)">{{LangHandler('o9query','查询')}}</el-button>
          <el-button size="mini" @click="resetting">{{LangHandler('o9reset','重置')}}</el-button>
        </div>
      </div>
      <div class="elTable">
        <el-table :data="tableData" style="width: 100%;padding: 10px 0;"
          :default-sort="{ prop: 'date', order: 'descending' }" highlight-current-row
          @current-change="handleCurrentChange2">
          <el-table-column v-if="columnNum == '站点'" prop="CODE" :label="LangHandler('deptid','部门编号')" sortable>
          </el-table-column>
          <el-table-column v-if="columnNum == '站点'" prop="FULLNAME" :label="LangHandler('deptname','部门名称')" sortable>
          </el-table-column>
          <el-table-column v-if="columnNum == '模板' || columnNum == '设备'" prop="MACHINE_TYPE" label="类型" sortable>
          </el-table-column>
          <el-table-column v-if="columnNum == '设备'" prop="MACHINE_NAME" label="名称" sortable>
          </el-table-column>
          <el-table-column v-if="columnNum == '模板'" prop="TEMPDESC" label="名称" sortable>
          </el-table-column>
          <el-table-column v-if="columnNum == '设备'" prop="ASSETNUMBER" label="编号" sortable>
          </el-table-column>
          <el-table-column v-if="columnNum == '模板'" prop="TNUM" label="编号" sortable>
          </el-table-column>
        </el-table>
      </div>
      <div class="elPagination">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage4"
          :page-sizes="[10, 20, 30, 40, 50, 60, 80, 100, 150, 200]" :page-size="10"
          layout="total, sizes, prev, pager, next, jumper" :total="totalCount">
        </el-pagination>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible2 = false">{{LangHandler('cancle','取 消')}}</el-button>
        <el-button type="primary" @click="dialogVisible2 = false">{{LangHandler('confirm','确 定')}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      yearShowOne: false,
      ruleForm: {
        region: "",
        regionName: "",
        region1: "",
        region1Name: "",
        region2: "",
        region3: "",
        region4: "",
        region4Name: "",
        date1: '',
        resource: "",
      },
      input: "",
      input1: "",
      columnNum: "",
      titleName: "",
      tableData: [],
      totalCount: 0,
      newarr: [],
      newarr0: [],
      newarr1: [
        {
          name: this.LangHandler('ogroup','集团'),
          id: 1
        },
        {
          name: this.LangHandler('division','事业部'),
          id: 2
        },
        {
          name: this.LangHandler('company','公司'),
          id: 3
        },
        {
          name: this.LangHandler('site','站点'),
          id: 4
        }
      ],
      newarr2: [
        {
          name: this.LangHandler('day','天'),
          id: 1
        },
        {
          name: this.LangHandler('month','月'),
          id: 2
        },
        {
          name: this.LangHandler('year','年'),
          id: 3
        }
      ],
      newarr3: [
        {
          name: this.LangHandler('energyreport','能源报告'),
          id: 1
        },
        {
          name: this.LangHandler('eqreport','设备报告'),
          id: 2
        },
        {
          name: this.LangHandler('omreport','运维报告'),
          id: 3
        },
        {
          name: this.LangHandler('carbonreport','碳排放报告'),
          id: 4
        }
      ],
      currentPage4: 1,
      limit: "10",
      page: "1",
      dialogVisible2: false,
      rules: {
        region1: [
          { required: true, message: "请选择报告类型", trigger: "change" },
        ],
        regionName: [
          { required: true, message: "请选择组织", trigger: "change" },
        ],
        region2: [
          { required: true, message: "请选择组织类型", trigger: "change" },
        ],
        region3: [
          { required: true, message: "请选择时间类型", trigger: "change" },
        ],
      },
    };
  },
  watch: {
    dialogVisible2(val) {
      if (!val) {
        this.input = ''
        this.input1 = ''
      }
      this.currentPage4 = 1
      this.limit = "10"
      this.page = "1"
    },
    'ruleForm.region2': {
      handler: function () {
        this.ruleForm.region =  ""
        this.ruleForm.regionName = ""
      }
    },
  },
  methods: {
    openChangeOne(status) {
      if (status) {
        this.yearShowOne = true
      }
    },
    panelChangeOne(value) {
      this.ruleForm.date1 = value
      this.yearShowOne = false
    },
    preview() {
      if (this.ruleForm.region1 == '' || this.ruleForm.date1 == "" || this.ruleForm.region2 == "" || this.ruleForm.regionName == "" || this.ruleForm.region3 == "") {
        this.$message.warning("您有必选项未选择！");
        return
      }
      let queryData = this.ruleForm
      if (this.ruleForm.region1 == '能源报告') {
        let routeUrl = this.$router.resolve({
          path: "/energyReport",
          query: queryData
        });
        window.open(routeUrl.href, "_blank");
      } else if (this.ruleForm.region1 == '设备报告') {
        let routeUrl = this.$router.resolve({
          path: "/deviceReport",
          query: queryData
        });
        window.open(routeUrl.href, "_blank");
      } else if (this.ruleForm.region1 == '运维报告') {
        let routeUrl = this.$router.resolve({
          path: "/opexReport",
          query: queryData
        });
        window.open(routeUrl.href, "_blank");
      } else if (this.ruleForm.region1 == '碳排放报告') {
        let routeUrl = this.$router.resolve({
          path: "/carbonReport",
          query: queryData
        });
        window.open(routeUrl.href, "_blank");
      }
      this.$parent.$parent.dialogVisible2 = false
    },
    xbtn() {
      this.$parent.$parent.dialogVisible2 = false
    },
    formatter(row, column) {
      return row.address;
    },
    download() {
      if (this.ruleForm.region1 == "" || this.ruleForm.region == "") {
        this.$message.warning("您有必选项未选择！");
        return
      }
      var queryType = "";
      var startTime = "";
      var endTime = "";
      if (this.ruleForm.resource == "15分钟") {
        queryType = "mintue15";
      } else if (this.ruleForm.resource == "原始数据") {
        queryType = "mintue";
      }
      if (this.ruleForm.date1.length != 0) {
        startTime = this.ruleForm.date1[0];
        endTime = this.ruleForm.date1[1];
      }
      let data = {
        equipmentType: this.ruleForm.region3,
        templateNum: this.ruleForm.region1,
        deptCode: this.ruleForm.region,
        queryType: queryType,
        equipmentNum: this.ruleForm.region4,
        startTime: startTime,
        endTime: endTime,
      };
      let url =
        Config.dev_url +
        `/api-apps/kafka/es/data/export?equipmentType=${this.ruleForm.region3}&templateNum=${this.ruleForm.region1}&deptCode=${this.ruleForm.region}&queryType=${queryType}&equipmentNum=${this.ruleForm.region4}&startTime=${startTime}&endTime=${endTime}`;
      window.open(url);
    },
    onclick(e) {
      let data = {};
      let url = "";
      if (e == "站点") {
        data = {
          page: this.page,
          limit: this.limit,
          DEPTTYPE_wheres: this.ruleForm.region2,
          CODE: this.input,
          FULLNAME: this.input1,
        };
        this.columnNum = "站点";
        this.titleName = "请选择站点";
        url = Config.dev_url + "/api-apps/app/DEPT/list";
      } else if (e == "模板") {
        data = {
          page: this.page,
          limit: this.limit,
          MACHINE_TYPE: this.ruleForm.region3,
          TEMPDESC: this.input,
        };
        this.columnNum = "模板";
        this.titleName = "请选择模板";
        url = Config.dev_url + "/api-apps/app/DIANBIAO/list";
      } else if (e == "设备") {
        data = {
          page: this.page,
          limit: this.limit,
          MACHINE_STATION_wheres: "",
          MACHINE_TYPE_wheres: "",
          TEMPNUM_wheres: this.ruleForm.region1,
          MACHINE_NAME: this.input1,
          MACHINE_TYPE: this.input,
        };
        this.columnNum = "设备";
        this.titleName = "请选择设备";
        url = Config.dev_url + "/api-apps/app/EQUIPMENT/list";
      }
      this.$axios.post(url, data).then((res) => {
        this.tableData = res.data.data;
        this.totalCount = Number(res.data.count)
      });
      this.dialogVisible2 = true;
    },
    findByCodes() {
      let url = Config.dev_url + "/api-user/dicts/findByCodes";
      let data = {
        code: "EQUPMENT_MODELI,QUARTER,DJLX,IOTTYPE,CHAIFA,ASSET_LEVEL,EVENTTYPE",
      };
      this.$axios.post(url, data).then((res) => {
        this.newarr = res.data.EQUPMENT_MODELI.subDicts
        for (const key in res.data) {
          if (Object.hasOwnProperty.call(res.data, key)) {
            const element = res.data[key];
            this.newarr0.push(element);
          }
        }
      });
    },
    onChange(item) {
      console.log(item)
      this.newarr0.forEach((val) => {
        console.log(val)
        if (item == '配电' && '配电采集设备类型' == val.name) {
          this.newarr1 = val.subDicts;
        } else if (item == '光伏' && '光伏采集设备类型' == val.name) {
          this.newarr1 = val.subDicts;
        } else if (item == '储能' && '储能采集设备类型' == val.name) {
          this.newarr1 = val.subDicts;
        } else if (item == '柴发' && '柴发' == val.name) {
          this.newarr1 = val.subDicts;
        } else if (item == '充电桩' && '充电桩' == val.name) {
          this.newarr1 = val.subDicts;
        }
      });
    },
    onChange1(item) {
      this.ruleForm.region1 = item;
    },
    onChange2(item) {
      this.ruleForm.region2 = item;
    },
    onChange3(item) {
      this.ruleForm.region3 = item;
    },
    resetting() {
      (this.input = ""), (this.input1 = ""), this.onclick(this.columnNum);
    },
    handleSizeChange(value) {
      this.limit = value;
      this.onclick(this.columnNum);
    },
    handleCurrentChange(val) {
      this.page = val;
      this.onclick(this.columnNum);
    },
    handleCurrentChange2(val) {
      if (val != null) {
        if (this.columnNum == "站点") {
          this.ruleForm.region = val.CODE;
          this.ruleForm.regionName = val.FULLNAME;
        } else if (this.columnNum == "模板") {
          this.ruleForm.region1 = val.TNUM;
          this.ruleForm.region1Name = val.TEMPDESC;
        } else if (this.columnNum == "设备") {
          this.ruleForm.region4 = val.ASSETNUMBER;
          this.ruleForm.region4Name = val.MACHINE_NAME
        }
      }
    },
  },
  mounted() {
    this.findByCodes();
  },
};
</script>
<style  lang="less">
.OperationCom7 {
  position: relative;

  .title_n {
    padding: 20px 0 20px 20px;
  }

  .contant_t {
    margin-left: 12px;
    padding: 20px;
  }

  .el-select-dropdown {
    min-width: 0 !important;
  }

  .el-date-editor--daterange.el-input__inner {
    width: 320px;
    height: 32px;
  }

  .el-date-editor .el-range__icon {
    line-height: 25px;
  }

  .el-date-editor .el-range-separator {
    width: 8%;
    line-height: 25px;
  }

  .dialog-footer {
    margin-left: 80%;
  }
}

.reseDialogt {
  .elDialog {
    width: 100%;
    display: flex;

    .elDialog_div {
      display: flex;
      margin-left: 20px;

      span {
        display: inline-block;
        width: 90px;
        height: 32px;
        line-height: 32px;
      }

      .el-input__inner {
        height: 32px;
        line-height: 32px;
      }

      .el-button {
        padding: 0;
        height: 32px;
        line-height: 32px;
      }
    }
  }

  .el-table__body-wrapper {
    height: 400px;
    overflow-y: auto;
  }
}

.elPagination {
  display: flex;
  justify-content: flex-end;
}

.icon_style {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.footerBtn {
  display: flex;
  justify-content: flex-end;
}
</style>
<style lang="less" scoped>
/deep/.el-form .el-form-item .el-form-item__content .el-input .el-input__inner{
  cursor: pointer;
}
</style>